import React, { useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { toSlug } from "../../utils/toSlug";

export const getNews = graphql`
  query getAllNews {
    allContentfulNews(limit: 1) {
      edges {
        node {
          title
        }
      }
    }
  }
`;

const News = ({ data: { allContentfulNews } }) => {
  const latestArticle = allContentfulNews.edges[0].node;
  const articleTitleToSlug = toSlug(latestArticle.title);

  useEffect(() => {
    navigate(articleTitleToSlug);
  }, []);

  return <div></div>;
};

export default News;
